import { message as antMessage } from "antd";
const SHOW_ERRORS = ["ValidationError", "ClientError"];

export const message = {
  success: (msg) => antMessage.success(msg),
  warning: (msg) => antMessage.warning(msg),
  error: (msg, e) => {
    if (e?.graphQLErrors) {
      let errors = [];

      for (const err of e.graphQLErrors) {
        const typeError = err.extensions?.exception?.name;
        const showError = SHOW_ERRORS.find((e) => e === typeError);
        if (showError) {
          errors.push(err.message);
        }
      }

      if (errors.length) return errors.forEach((e) => antMessage.error(e));
    }

    return antMessage.error(msg);
  },
};
