import React, { useEffect, useState } from "react";
import { Card, Table, Input, Modal } from "antd";
import ButtonApp from "../Button";
import {
  deleteProducts,
  getProducts,
  updateProducts,
  updateProductsUrl,
} from "../../services/ProductsServices";
import { formatoMonedaChilena } from "../../utils";
import { message } from "../Message";
import ProductModal from "./productsModal";
import LoadingSpinner from "../LoadingSpinner";
import { uploadFile, uploadMultipleImages } from "../../services/uploadFile";
import AlertMessage from "../../components/AlertMessage";
import { useShoppingCartContext } from "../../context/shoppingCard";

const Products = () => {
  const { user } = useShoppingCartContext();
  const [data, setData] = useState(null);
  const [viewInput, setViewInput] = useState(false);
  const [inputData, setInputData] = useState({
    value: null,
    id: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(false);
  const [images, setImages] = useState(false);
  const [viewImage, setViewImage] = useState(true);
  const [loadingMultiple, setLoadingMultiple] = useState(false);
  const [messageFinally, setMessageFinally] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState({
    showModal: false,
    id: null,
    name: "",
  });
  const [viewInputUpload, setViewInputUpload] = useState({
    active: false,
    id: null,
  });

  const showModal = () => {
    setIsModalOpen(true);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const datos = await getProducts(user?.processId);
      setData(
        datos.map((p) => {
          return {
            ...p,
            status: p.stock > 0 ? "Activo" : "Inactivo",
            price: formatoMonedaChilena(p.price),
          };
        })
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error al obtener datos:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [isModalOpen]);

  const handleDataChange = (data) => {
    const value = data.currentTarget.value;
    setInputData({
      ...inputData,
      value: parseInt(value || "1"),
    });
  };

  const onFinish = async () => {
    if (inputData.id === null || inputData.value === null) {
      message.warning("Debe ingresar el stock");
      return;
    }
    setIsLoading(true);
    const dataUpdate = {
      stock: inputData.value,
    };
    const respont = await updateProducts(dataUpdate, inputData.id);
    fetchData();
    setInputData({
      id: null,
      value: null,
    });
    setViewInput(false);
    setIsLoading(false);
  };

  const onFinishDelete = async () => {
    if (isModalVisible.id == null || isModalVisible.id <= 0) {
      message.error("Ha ocurrido un error");
    }
    setIsLoading(true);
    const respont = await deleteProducts(isModalVisible.id);
    fetchData();
    setIsLoading(false);
    setIsModalVisible({ showModal: false, id: null, name: "" });
  };

  const changeInput = async (id) => {
    setViewInput(true);
    setInputData({
      ...inputData,
      id,
    });
  };

  const uploadImageProduct = async () => {
    try {
      const upload = await uploadFile(image.name, image);
      const data = {
        url: upload,
      };
      const result = updateProductsUrl(data, viewInputUpload.id);
      message.success("Imagen cargada con exito");
    } catch (error) {
      console.log("Error", error);
      message.error("No se ha podido completar la solicitud");
    }
    setViewInputUpload({
      active: false,
      id: null,
    });
  };

  const activeUpload = async (id) => {
    if (id === null || id === undefined) {
      message.error("Ha ocurrido un error");
      return;
    }
    setViewInputUpload({
      active: true,
      id,
    });
  };

  const handleFileChange = (e) => {
    if (e.target.files) {
      setImage(e.target.files[0]);
    }
  };

  const onFilesSelected = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedImages = Array.from(e.target.files);
      setImages(selectedImages);
    }
  };

  const uploadImageMultipleProduct = async () => {
    try {
      setLoadingMultiple(true);
      const upload = await uploadMultipleImages(images);
      setLoadingMultiple(false);
      setMessageFinally([...messageFinally, '-'])
      console.log(upload);
    } catch (error) {
      console.log("Error", error);
      message.error("No se ha podido completar la solicitud");
    }
    setViewInputUpload({
      active: false,
      id: null,
    });
  };

  const showModalDelete = (id, name) => {
    setIsModalVisible({ showModal: true, id: id, name: name });
  };

  const handleCancel = () => {
    setIsModalVisible({ showModal: false, id: null, name: "" });
  };

  const viewImageMasive = () => {
    setViewImage(!viewImage);
  };

  const columns = [
    {
      title: "Nombre Producto",
      dataIndex: "name",
      key: "name",
      filters: data?.map((d) => {
        return {
          text: d.name,
          value: d.name,
        };
      }),
      onFilter: (value, record) => record.name.startsWith(value),
      filterSearch: true,
    },
    {
      title: "Sku",
      dataIndex: "sku",
      key: "sku",
      filters: data?.map((d) => {
        return {
          text: d.sku,
          value: d.sku,
        };
      }),
      onFilter: (value, record) => record.sku.indexOf(value) === 0,
      filterSearch: true,
    },
    {
      title: "Precio",
      dataIndex: "price",
      key: "price",
    },
    /* {
      title: "Estado",
      dataIndex: "status",
      key: "status",
    }, */
    {
      title: "Stock",
      dataIndex: "stock",
      key: "stock",
      width: 100,
    },
    {
      title: "Aciones",
      key: "action",
      render: (_, record) => (
        <>
          <ButtonApp
            styleButton="mr-2"
            children={"Cambiar Stock"}
            onClick={() => changeInput(record.id)}
            loading={isLoading}
          />
          <ButtonApp
            styleButton="mr-2"
            children={"Eliminar Producto"}
            onClick={() => showModalDelete(record.id, record.name)}
            loading={isLoading}
          />
          {/* <ButtonApp
            styleButton="mr-2 bg-blue-500 text-white"
            icon={<UploadOutlined />}
            tooltip="Cargar imagen de producto"
            onClick={() => activeUpload(record.id)}
          /> */}
        </>
      ),
    },
  ];

  if (loading) {
    return <LoadingSpinner text="Cargando..." />;
  }

  return (
    <div>
      <div className="mb-2">
        { messageFinally?.map(x => (
          <AlertMessage
            title={"Finalizado"}
            message={
              `La carga de imagen ha finalizado correctamente`
            }
            type='success'
            onClose={true}
          />
        ))}
      </div>
      <Card className="w-full" bordered={false}>
        <Card className="mb-4" hidden={viewImage}>
          <AlertMessage
            title={"Advertencia"}
            message={
              "El nombre de la foto debe ser el mismo al SKU del producto"
            }
          />
          <ButtonApp
            styleButton="m-2 bg-blue-500 text-white"
            onClick={onFilesSelected}
            type={"imageMultiple"}
          />
          <ButtonApp
            loading={loadingMultiple}
            styleButton="m-2"
            children={"Carga masiva de imagenes"}
            onClick={() => uploadImageMultipleProduct()}
          />
        </Card>
        {viewInput && (
          <div className="">
            <Input placeholder="Stock" onChange={handleDataChange} />
            <ButtonApp
              loading={isLoading}
              disabled={isLoading}
              styleButton="mt-2 mb-2"
              children={"Guardar Stock"}
              onClick={() => onFinish()}
            />
          </div>
        )}
        <div className="flex flex-col lg:flex-row lg:justify-end">
          <ButtonApp
            children={"Cargar Imagen Masivo"}
            styleButton="mb-2 lg:mb-0 lg:mr-2"
            onClick={() => viewImageMasive()}
          />
          <ButtonApp
            children={"Crear Productos"}
            styleButton="mb-2"
            onClick={() => showModal()}
          />
        </div>
        {viewInputUpload.active && (
          <div className="">
            <ButtonApp
              styleButton="m-2 bg-blue-500 text-white"
              onClick={handleFileChange}
              type={"image"}
            />
            <ButtonApp
              styleButton="m-2"
              children={"Cargar imagen"}
              onClick={() => uploadImageProduct()}
            />
          </div>  
        )}
        <Table dataSource={data} columns={columns} scroll={{ x: 'max-content' }} />
      </Card>
      {isModalOpen && (
        <ProductModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      )}  
      <Modal
        title="Confirmar Eliminación"
        onOk={() => onFinishDelete()}
        onCancel={() => handleCancel()}
        open={isModalVisible.showModal}
        okButtonProps={{
          style: {
            backgroundColor: "rgb(59 130 246)",
            borderColor: "rgb(59 130 246)",
          },
        }}
      >
        <h1 className="mb-2"> Producto: {isModalVisible.name} </h1>
        <p>¿Estás seguro de que quieres eliminar este producto?</p>
      </Modal>
    </div>
  );
};

export default Products;
