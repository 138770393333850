import { add0InNumber, formatoMonedaChilena } from "../utils";

export const salesNoteTemplate = (data, user, total, saleNoteId) => {
  const [monthString, yearString] = user?.processYearMonth?.split('-');
  const month = parseInt(monthString, 10);
  const year = parseInt(yearString, 10);
  
  const date = new Date(year, month - 1);
  const today = new Date();
  console.log(date)

  const dd = {
    content: [
      { text: "Nota de Venta Essity", style: "header" },
      {
        style: "tableExample",
        table: {
          headerRows: 1,
          widths: ["*", "auto", "auto", "auto"],
          body: [
            [
              { text: "Nombre Producto", style: "tableHeader" },
              { text: "Precio", style: "tableHeader" },
              { text: "Cantidad", style: "tableHeader" },
              { text: "Sub-Total", style: "tableHeader" },
            ],
            ...data.map((x) => {
              return [x.name, x.price, x.quantity, x.subPrice];
            }),
          ],
        },
      },
      { text: "Detalle:", fontSize: 14, bold: true, margin: [0, 30, 0, 15] },
      {
        style: "tableExample",
        table: {
          headerRows: 1,
          body: [
            [`Nombre: ${user.fullName}`],
            [`Rut: ${user.rut}`],
            [`Periodo: ${ add0InNumber(date.getMonth() + 1) }-${ date.getFullYear() }`],
            [`Número Solicitud: ${ saleNoteId }`],
            [
              `Fecha Solicitud: ${ add0InNumber(today.getDate()) }-${
                add0InNumber(today.getMonth() + 1)
              }-${today.getFullYear()}`,
            ],
            [`Total Solicitud: ${formatoMonedaChilena(total)}`],
          ],
        },
        layout: "noBorders",
      },
    ],
    styles: {
      header: {
        alignment: "center",
        fontSize: 18,
        bold: true,
        margin: [0, 0, 0, 40],
      },
      subheader: {
        fontSize: 16,
        bold: true,
        margin: [0, 10, 0, 5],
      },
      tableExample: {
        widths: ["*", "auto", 100, "*"],
        margin: [0, 5, 0, 15],
      },
      tableHeader: {
        bold: true,
        fontSize: 13,
        color: "black",
      },
    },
    defaultStyle: {
      alignment: "justify",
    },
  };
  return dd;
};