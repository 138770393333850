import React, { createContext, useContext, useState } from "react";

const ShoppingCartContext = createContext();

const ShoppingCartProvider = ({ children }) => {
  const [allProducts, setAllProducts] = useState([]);
  const [total, setTotal] = useState(0);
  const [countProducts, setCountProducts] = useState(0);
  const [user, setUser] = useState(0);
  const [saleNote, setSaleNote] = useState(null);

  return (
    <ShoppingCartContext.Provider
      value={{
        allProducts,
        setAllProducts,
        total,
        setTotal,
        countProducts,
        setCountProducts,
        user,
        setUser,
        saleNote, 
        setSaleNote,
      }}
    >
      {children}
    </ShoppingCartContext.Provider>
  );
};

const useShoppingCartContext = () => {
  const context = useContext(ShoppingCartContext);
  if (!context) {
    throw new Error(
      "useShoppingCartContext debe ser utilizado dentro de un ShoppingCartProvider"
    );
  }
  return context;
};

export { ShoppingCartProvider, useShoppingCartContext };
