import React, { useEffect, useState } from "react";
import { Card, Input, Table } from "antd";
import ButtonApp from "../Button";
import { message } from "../Message";
import LoadingSpinner from "../LoadingSpinner";
import {
  getSyndicateConfig,
  updateSyndicateConfig,
} from "../../services/SyndicateConfigServices";

const SyndicateConfig = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingBody, setLoadingBody] = useState(false);
  const [viewInput, setViewInput] = useState({
    id: 0,
    view: false,
    value: 0,
  });

  const fetchData = async () => {
    setLoading(true);
    try {
      const datos = await getSyndicateConfig();
      setData(datos);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error al obtener datos:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onFinish = async () => {
    if (
      !viewInput.id ||
      !viewInput.value ||
      viewInput.id === null ||
      viewInput.value === null
    ) {
      message.warning("Debe ingresar un numero");
      return;
    }
    setLoadingBody(true);
    const result = updateSyndicateConfig(
      { candProducto: viewInput.value },
      viewInput.id
    );

    setViewInput({
      id: 0,
      view: false,
      value: 0,
    });
    fetchData();
    setLoadingBody(false);
  };

  const changeInput = async (id) => {
    setViewInput({
      id,
      view: true,
    });
  };

  const handleDataChange = (data) => {
    const value = data.currentTarget.value;
    setViewInput({
      ...viewInput,
      value: parseInt(value || "0"),
    });
  };

  const columns = [
    {
      title: "Nombre Sindicato",
      dataIndex: "syndicateNameRbk",
      key: "syndicateNameRbk",
      width: "30%",
      filters: data?.map((d) => {
        return {
          text: d.syndicateNameRbk,
          value: d.syndicateNameRbk,
        };
      }),
      onFilter: (value, record) => record.syndicateNameRbk.startsWith(value),
      filterSearch: true,
    },
    {
      title: "Rut Sindicato",
      dataIndex: "syndicateRutRbk",
      key: "syndicateRutRbk",
      width: "15%",
      filters: data?.map((d) => {
        return {
          text: d.syndicateRutRbk,
          value: d.syndicateRutRbk,
        };
      }),
      onFilter: (value, record) => record.syndicateRutRbk.startsWith(value),
      filterSearch: true,
    },
    {
      title: "Cantidad Producto",
      dataIndex: "candProducto",
      key: "candProducto",
      width: "16%",
      filters: data?.map((d) => {
        return {
          text: d.candProducto,
          value: d.candProducto,
        };
      }),
      onFilter: (value, record) => record.candProducto.indexOf(value) === 0,
      filterSearch: true,
    },
    {
      title: "Aciones",
      key: "action",
      render: (_, record) => (
        <ButtonApp
          styleButton="mr-2"
          children={"Cambiar Cantidad"}
          onClick={() => changeInput(record.id)}
        />
      ),
    },
  ];

  if (loading) {
    return <LoadingSpinner text="Cargando..." />;
  }

  return (
    <div>
      <Card className="w-full" bordered={false}>
        {viewInput.view && (
          <div className="">
            <Input placeholder="Stock" onChange={handleDataChange} />
            <ButtonApp
              loading={loadingBody}
              disabled={loadingBody}
              styleButton="mt-2 mb-2 mr-2"
              children={"Guardar Stock"}
              onClick={() => onFinish()}
            />
            <ButtonApp
              loading={loadingBody}
              disabled={loadingBody}
              styleButton="mt-2 mb-2"
              children={"Cancelar"}
              onClick={() =>
                setViewInput({
                  id: 0,
                  view: false,
                  value: 0,
                })
              }
            />
          </div>
        )}
        <Table dataSource={data} columns={columns} scroll={{ x: 'max-content' }} />
      </Card>
    </div>
  );
};

export default SyndicateConfig;
