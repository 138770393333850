import { useShoppingCartContext } from "../../context/shoppingCard";
import LoadingSpinner from "../../components/LoadingSpinner";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { decryptToken } from "../../services/TokenServices";
import { encryptText } from "../../utils";

function Login() {
  const navigate = useNavigate();
  const { setUser, user } = useShoppingCartContext();
  const [loading, setLoading] = useState(false);

  const { type, token } = useParams();
  const fetchData = async () => {
    setLoading(true);
    try {
      const datos = await decryptToken({ token: token });
      sessionStorage.setItem("token", token);
      const encrypt = encryptText(type);
      sessionStorage.setItem("module", encrypt);
      setUser({ ...datos?.user, token } || null);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error al obtener datos:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return <LoadingSpinner text="Cargando..." />;
  }

  if (user) {
    const token = sessionStorage.getItem("token");
    switch (type) {
      case "admin":
        navigate(`/essity_rbk/admin/${user?.userId}`);
        break;
      case "ecommerce":
        navigate(`/essity_rbk/ecommerce/${user?.userId}`);
        break;
      default:
        break;
    }
  }

  return <header className="flex flex-col items-center"></header>;
}

export default Login;
