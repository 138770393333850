import { message } from "antd";
import AWS from "aws-sdk";

const {
  REACT_APP_ACCESS_KEY_ID,
  REACT_APP_SECRET_ACCESS_KEY,
  REACT_APP_BUCKET_UPLOAD,
  REACT_APP_NODE_ENV,
} = process.env;

const s3 = new AWS.S3({
  accessKeyId: REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: REACT_APP_SECRET_ACCESS_KEY,
});

const uploadFile = async (newNameFile, file) => {
  const key = `${REACT_APP_NODE_ENV}/products/image/${newNameFile}`;
  const data = await s3
    .putObject({
      Bucket: REACT_APP_BUCKET_UPLOAD,
      Key: key,
      Body: file,
      ContentType: file.type,
    })
    .promise();

  /* const url = `https://${REACT_APP_BUCKET_UPLOAD}.s3.amazonaws.com/${key}`;

  const params = {
    Bucket: REACT_APP_BUCKET_UPLOAD,
    Key: key,
  };
  const signedUrl = await s3.getSignedUrlPromise("getObject", params); */
  return key;
};

const getSignedUrl = async (key) => {
  const params = {
    Bucket: REACT_APP_BUCKET_UPLOAD,
    Key: key,
  };
  const signedUrl = await s3.getSignedUrlPromise("getObject", params);

  return signedUrl;
};

const uploadMultipleImages = async (imageFiles) => {
  try {
    const uploadPromises = imageFiles.map(async (file, index) => {
      const newNameFile = `${file.name}`;
      const key = `${REACT_APP_NODE_ENV}/products/image/${newNameFile.split('.')[0]}`;
      
      await s3.putObject({
        Bucket: REACT_APP_BUCKET_UPLOAD,
        Key: key,
        Body: file,
        ContentType: file.type,
      }).promise();

      return key;
    });

    const uploadedKeys = await Promise.all(uploadPromises);
    message.success(`Se han cargado ${uploadedKeys.length} imágenes con éxito`);
    return uploadedKeys; 
  } catch (error) {
    console.error("Error", error);
    message.error("No se han podido cargar todas las imágenes");
  }
};

const getProductsWithoutImage = async (products) => {
  const productsWithoutImage = [];

  for (const product of products) {
    const key = product.url; 
    const signedUrl = await getSignedUrl(key);
    const response = await fetch(signedUrl);

    if (response.ok) {
      product.imageExist = true;
      productsWithoutImage.push(product);
    } else {
      product.imageExist = false;
      productsWithoutImage.push(product);
    }
  }

  return productsWithoutImage;
};


export { uploadFile, getSignedUrl, uploadMultipleImages, getProductsWithoutImage };
