import { useState } from "react";
import { formatoMonedaChilena } from "../../utils";
import { message } from "../Message";
import { UpOutlined, DownOutlined, HomeOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import ButtonApp from "../Button";
const { REACT_APP_URL_RUBRIKA_APP } = process.env;

export const Header = ({
  allProducts,
  setAllProducts,
  total,
  countProducts,
  setCountProducts,
  setTotal,
  user,
}) => {
  const [active, setActive] = useState(false);

  const onDeleteProduct = (product) => {
    const results = allProducts.filter((item) => item.id !== product.id);

    setTotal(total - product.price * (product.quantity || 0));
    setCountProducts(countProducts - (product.quantity || 0));
    setAllProducts(results);
    message.success("Producto eliminado correctamente");
  };

  const onCleanCart = () => {
    setAllProducts([]);
    setTotal(0);
    setCountProducts(0);
    message.success("Carrito vaciado correctamente");
  };

  const setstockProduct = (increase, product) => {
    if (increase) {
      if (countProducts >= 10) {
        message.warning("Maximo 10 bultos");
        return;
      }
      if (
        user?.syndicateProdCount > 0 &&
        countProducts >= user?.syndicateProdCount
      ) {
        message.warning(
          `Maximo ${user.syndicateProdCount} bultos por el sindicato`
        );
        return;
      }
      if (allProducts.find((item) => item.id === product.id)) {
        const products = allProducts.map((item) =>
          item.id === product.id
            ? { ...item, quantity: (item.quantity || 0) + 1 }
            : item
        );
        const productStock = products.find((ps) => ps.id === product.id);
        if (productStock.quantity > productStock.stock) {
          message.warning("Sin Stock");
          return;
        } else {
          setTotal(total + product.price);
          setCountProducts(countProducts + 1);
          return setAllProducts([...products]);
        }
      }
    } else {
      if (allProducts.find((item) => item.id === product.id)) {
        const products = allProducts.map((item) =>
          item.id === product.id
            ? { ...item, quantity: (item.quantity || 0) - 1 }
            : item
        );
        if (product.quantity === 1) return onDeleteProduct(product);
        else setTotal(total - product.price);
        setCountProducts(countProducts - 1);
        return setAllProducts([...products]);
      }
    }
  };

  return (
    <header>
      <h1 className="text-left text-3xl font-bold"> Tienda </h1>
      <div className="container-icon">
        <ButtonApp
          children={
            <Link to={REACT_APP_URL_RUBRIKA_APP}> Rubrika </Link>
          }
          icon={<HomeOutlined />}
          tooltip="Volver a Rubrika"
          styleButton="mr-2"
        />
      </div>
      <div className="container-icon">
        <div className="container-cart-icon" onClick={() => setActive(!active)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="icon-cart"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
            />
          </svg>
          <div className="count-products">
            <span id="contador-productos">{countProducts}</span>
          </div>
        </div>

        <div
          className={`container-cart-products ${active ? "" : "hidden-cart"} mx-auto max-w-screen-md`}
        >
          {allProducts.length ? (
            <>
              <div className="flex flex-col">
                {allProducts.map((product) => (
                  <div
                    className="flex flex-col md:flex-row justify-between items-center p-4 md:p-8 border-b border-gray-200"
                    key={product.id}
                  >
                    <div className="flex justify-between flex-grow-0.8 w-96">
                      <span className="mr-2 text-left text-base">
                        {product.name}
                      </span>
                      <div>
                        <span className="cantidad-producto-carrito">
                          {product.quantity + "-"}
                        </span>
                        <span className="precio-producto-carrito">
                          {formatoMonedaChilena(product.price)}
                        </span>
                      </div>
                    </div>
                    <div className="flex justify-end ">
                      <div className="items-center">
                        <ButtonApp
                          icon={<UpOutlined />}
                          onClick={() => setstockProduct(true, product)}
                        />
                        <ButtonApp
                          icon={<DownOutlined />}
                          onClick={() => setstockProduct(false, product)}
                        />
                      </div>
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="icon-close"
                          onClick={() => onDeleteProduct(product)}
                        >
                          <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="cart-total">
                <h3>Total:</h3>
                <span className="total-pagar">
                  {formatoMonedaChilena(total)}
                </span>
              </div>

              <div className="m-2">
                <ButtonApp
                  styleButton={"m-2"}
                  color={"black"}
                  children={"Vaciar Carrito"}
                  onClick={() => onCleanCart()}
                />
                <ButtonApp
                  styleButton={"m-2"}
                  color={"black"}
                  children={
                    <Link to={"/essity_rbk/ecommerce/shopping"}>
                      {" "}
                      Ver Carrito{" "}
                    </Link>
                  }
                />
              </div>
            </>
          ) : (
            <p className="cart-empty">El carrito está vacío</p>
          )}
        </div>
      </div>
    </header>
  );
};
