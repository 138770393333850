import React, { useState } from "react";
import { Button, Checkbox, Form, Input, InputNumber, Modal } from "antd";
import ButtonApp from "../Button";
import { createProducts } from "../../services/ProductsServices";
import { useShoppingCartContext } from "../../context/shoppingCard";

const ProductModal = ({ isModalOpen, setIsModalOpen }) => {
  const { user } = useShoppingCartContext();
  const [isLoading, setIsLoading] = useState(false);

  const onFinish = async (values) => {
    if (!values) {
      return;
    }
    setIsLoading(true);
    const data = {
      ...values,
      url: "",
      processId: user.processId,
    };
    const result = await createProducts(data);
    setIsLoading(false);
    setIsModalOpen(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal
      title="Producto"
      open={isModalOpen}
      onCancel={handleCancel}
      footer={null}
    >
      <Form
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{
          maxWidth: 600,
        }}
        initialValues={{
          remember: true,
        }}
        autoComplete="off"
        onFinish={onFinish}
      >
        <Form.Item
          label="Nombre Producto"
          name="name"
          rules={[
            {
              required: true,
              message: "Favor de ingresar el nombre del producto!",
            },
          ]}
        >
          <Input maxLength={60} placeholder="Maximo 60 caracteres" />
        </Form.Item>

        <Form.Item
          label="Sku Producto"
          name="sku"
          rules={[
            {
              required: true,
              message: "Favor de ingresar el sku del producto!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Precio Producto"
          name="price"
          rules={[
            {
              required: true,
              message: "Favor de ingresar el precio del producto!",
            },
          ]}
        >
          <InputNumber />
        </Form.Item>

        <Form.Item
          label="Stock Producto"
          name="stock"
          rules={[
            {
              required: true,
              message: "Favor de ingresar el stock del producto!",
            },
          ]}
        >
          <InputNumber />
        </Form.Item>

        <Form.Item
          className="text-center"
          wrapperCol={{
            xs: { span: 24 },
            sm: { offset: 8, span: 16 },
          }}
        >
          <ButtonApp
            disabled={isLoading}
            loading={isLoading}
            htmlType="submit"
            children={"Guardar"}
            styleButton="m-2"
          />
          <ButtonApp
            disabled={isLoading}
            children={"Cancelar"}
            onClick={() => handleCancel()}
            styleButton="m-2"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default ProductModal;
