import { message } from "../components/Message";
// import { dataPrueba } from "../data";

const { REACT_APP_URL_API_NESTJS, REACT_APP_NODE_ENV } = process.env;
// const port = REACT_APP_NODE_ENV == 'dev' ? ':80' : REACT_APP_NODE_ENV == 'prod' ? ':8080' : ''
const url = `${REACT_APP_URL_API_NESTJS}/syndicateConfig`;

const getSyndicateConfig = async () => {
  const token = sessionStorage.getItem("token");
  try {
    const respuesta = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    });

    const datos = await respuesta.json();
    if (!datos.status) {
      return [];
    }
    return datos.data;
  } catch (error) {
    message.error("Ha ocurrido un error");
    console.error("Error al enviar datos:", error);
  }
};

const updateSyndicateConfig = async (data, id) => {
  const token = sessionStorage.getItem("token");
  try {
    const respuesta = await fetch(`${url}/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    const datos = await respuesta.json();
    if (!datos.status) {
      message.error(datos.message);
    } else {
      message.success(datos.message);
    }
    return datos.data;
  } catch (error) {
    message.error("Ha ocurrido un error");
    console.error("Error al enviar datos:", error);
  }
};

export { getSyndicateConfig, updateSyndicateConfig };
