import React from "react";
import ButtonApp from "../../components/Button";
import { VerticalAlignBottomOutlined } from "@ant-design/icons";
import { useShoppingCartContext } from "../../context/shoppingCard";
import { salesNoteTemplate } from "../../constans/saleNoteTemplate";
var pdfMake = require("pdfmake/build/pdfmake.js");
var pdfFonts = require("pdfmake/build/vfs_fonts.js");
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const SalesNoteGenerate = ({ data, total, saleNote }) => {
  const { user } = useShoppingCartContext();

  const downloadFile = () => {
    const dd = salesNoteTemplate(data, user, total, saleNote?.requestNumber || '');
    pdfMake
      .createPdf(dd)
      .download(`${user.processYearMonth}_Nota_de_venta_${user.rut}.pdf`);
  };

  return (
    <div>
      <ButtonApp
        styleButton="mt-2 mb-2 bg-blue-500 text-white"
        icon={<VerticalAlignBottomOutlined className="mr-2" />}
        children={"Descargar Nota de Venta"}
        onClick={() => downloadFile()}
        disabled={!user.acess}
      />
    </div>
  );
};

export default SalesNoteGenerate;
