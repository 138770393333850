import { Card, Select, Table, DatePicker } from "antd";
import React, { useEffect, useState } from "react";
import { getPeriods } from "../../services/PeriodsServices";
import ButtonApp from "../Button";
import { getReports } from "../../services/ReportsServices";
import {
  formatDate,
  formatoMonedaChilena,
  generateCode,
  generateCodeOnly,
} from "../../utils";
import GenerateExcel from "../GenerateExcel";
import { message } from "../Message";
import LoadingSpinner from "../LoadingSpinner";
const { RangePicker } = DatePicker;

const Reports = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [reportData, setReportData] = useState(null);
  const [optionData, setOptionData] = useState(null);
  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedDatesV2, setSelectedDatesV2] = useState([]);
  const [selectProcess, setSelectProcess] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const datos = await getPeriods();
      setOptionData(datos);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error al obtener datos:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const optionsProcess = optionData?.map((i) => {
    return {
      value: i.id,
      label: `${i.year}-${i.month} ${
        i.isOpen && i.status >= 1 ? "Abierto" : "Cerrado"
      }`,
    };
  });

  const generateReport = async () => {
    if (!selectProcess) {
      message.warning("Debe selecionar un periodo");
      return;
    }
    setIsLoading(true);
    const result = await getReports(
      selectProcess,
      selectedDatesV2[0] || 0,
      selectedDatesV2[1] || 0
    );
    const resultFormat = result.map((x) => {
      return {
        key: `${x.totalPrice}${generateCodeOnly()}`,
        fullName: x.fullName,
        totalPrice: x.totalPrice,
        date: x.date,
        rut: x.rut?.split("-")[0],
        saleNoteId: x.saleNoteId,
        requestNumber: x.requestNumber,
        children: x.saleNoteProducts.map((sn) => {
          return {
            ...sn,
            key: `${generateCodeOnly()}`,
          };
        }),
      };
    });

    setReportData(resultFormat);
    setIsLoading(false);
  };

  const handleChange = async (value) => {
    setSelectProcess(value);
  };

  const disabledDate = (current) => {
    const selected = optionData?.find((op) => op.id == selectProcess);

    if (!selected || !current instanceof Date) {
      return true;
    }

    const firstDayOfMonth = new Date(
      parseInt(selected.year),
      parseInt(selected.month) - 1,
      1
    );
    const firstDayOfNextMonth = new Date(
      parseInt(selected.year),
      parseInt(selected.month),
      1
    );

    return current < firstDayOfMonth || current > firstDayOfNextMonth;
  };

  const handleDateChange = (dates, dateStrings) => {
    console.log("Fechas seleccionadas:", dateStrings);
    setSelectedDates(dates);
    setSelectedDatesV2([ dateStrings[0].split('-')[0], dateStrings[1].split('-')[0] ]);
    console.log( "Fechas seleccionadas :", selectedDatesV2 )
  };

  const columns = [
    {
      title: "Nombre Usuario",
      dataIndex: "fullName",
      key: "fullName",
      width: "18%",
      render: (_, record) => <div>{record.fullName}</div>,
    },
    {
      title: "Rut Usuario",
      dataIndex: "rut",
      key: "rut",
      width: "18%",
      render: (_, record) => <div>{record.rut}</div>,
    },
    {
      title: "Fecha",
      dataIndex: "date",
      key: "date",
      width: "9%",
      render: (_, record) => (
        <div>{record.date ? formatDate("DDMMYYYY", record.date) : "-"}</div>
      ),
    },
    {
      title: "Número Guía",
      dataIndex: "saleNoteId",
      key: "saleNoteId",
      width: "9%",
    },
    {
      title: "Número Solicitud",
      dataIndex: "requestNumber",
      key: "requestNumber",
      width: "9%",
    },
    {
      title: "Valor Total",
      dataIndex: "totalPrice",
      key: "totalPrice",
      width: "13%",
      render: (_, record) => (
        <div>
          {record.totalPrice ? formatoMonedaChilena(record.totalPrice) : "-"}
        </div>
      ),
    },
    {
      title: "Nombre Producto",
      dataIndex: "name",
      key: "name",
      render: (_, record) => <div>{record.name || "-"}</div>,
    },
    {
      title: "SKU",
      dataIndex: "sku",
      key: "sku",
      render: (_, record) => <div>{record.sku || "-"}</div>,
    },
    {
      title: "Cantidad",
      dataIndex: "quantity",
      width: "10%",
      key: "quantity",
      render: (_, record) => <div>{record.quantity || "-"}</div>,
    },
    {
      title: "Precio",
      dataIndex: "price",
      key: "price",
      render: (_, record) => (
        <div>{record.price ? formatoMonedaChilena(record.price) : "-"}</div>
      ),
    },
    {
      title: "Sub-Total",
      dataIndex: "SubTotal",
      key: "SubTotal",
      render: (_, record) => (
        <div>
          {record.price
            ? formatoMonedaChilena(record.price * record.quantity)
            : "-"}
        </div>
      ),
    },
  ];

  if (loading) {
    return <LoadingSpinner text="Cargando..." />;
  }

  return (
    <div>
      <Select
        className="mt-2 mb-2 mr-2 w-60"
        defaultValue={{
          value: "NOT_DEFINITE",
          label: "Selecionar Periodo",
        }}
        onChange={handleChange}
        options={optionsProcess}
      />
      <RangePicker
        picker="date"
        disabledDate={disabledDate}
        format="DD-MM-YYYY"
        onChange={handleDateChange}
        value={selectedDates}
        disabled={!selectProcess}
      />
      <ButtonApp
        children={"Generar Reporte"}
        onClick={() => generateReport()}
        styleButton="m-2"
        loading={isLoading}
      />
      {reportData && (
        <Card className="w-full mt-2" bordered={false}>
          {/* <h2>Report Data</h2> */}
          {/* <pre>{JSON.stringify(reportData, null, 2)}</pre> */}
          <div className="mb-2 flex justify-end">
            <GenerateExcel data={reportData} />
          </div>
          <Table
            columns={columns}
            dataSource={reportData}
            scroll={{ x: "max-content" }}
          />
        </Card>
      )}
    </div>
  );
};

export default Reports;
