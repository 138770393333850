import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import NotFoundPage from "../../pages/NotFoundPage";
import ShoppingPage from "../../pages/Shopping";
import Admin from "../../pages/Admin";
import Home from "../../pages/Home";
import Login from "../../pages/Login";

const RouterApp = () => {
  const router = createBrowserRouter([
    {
      path: "/essity_rbk/ecommerce/:userId",
      element: <Home />,
    },
    {
      path: "/essity_rbk/ecommerce/shopping",
      element: <ShoppingPage />,
    },
    {
      path: "/essity_rbk/admin/:userId",
      element: <Admin />,
    },
    {
      path: "/essity_rbk/login/:type/:token",
      element: <Login />,
    },
    {
      path: "*",
      element: <NotFoundPage />,
    },
  ]);

  return <RouterProvider router={router} />;
};

export default RouterApp;
