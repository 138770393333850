import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";

const ButtonApp = ({
  children,
  icon,
  onClick,
  disabled,
  url,
  type,
  htmlType = "button",
  styleButton = "",
  color = "black",
  visible = true,
  loading = false,
  tooltip = "",
}) => {
  const colors = {
    black: "bg-black",
    primary: "bg-blue",
    warning: "bg-yellow",
    success: "bg-green",
    danger: "bg-red",
    login: "bg-indigo",
  };
  if (!visible) {
    return;
  }
  if (url) {
    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        <Button
          type="primary"
          className={`border-none bg-none ${colors[color]}-${
            disabled ? 200 : 500
          } text-white px-10 py-15 cursor-pointer ${styleButton}`}
          data-ripple-light="true"
          onClick={onClick}
          disabled={disabled}
        >
          <span>{loading ? <LoadingOutlined className="ml-1" /> : icon}</span>
          {children}
        </Button>
      </a>
    );
  }

  if (type === "file") {
    return <input type="file" accept=".xlsx, .xls" onClick={onClick}></input>;
  }

  if (type === "image") {
    return (
      <input
        className="btn"
        type="file"
        accept=".png, .jpg, .jpeg"
        id="fileID"
        onChange={onClick}
      />
    );
  }

  if (type === "imageMultiple") {
    return (
      <input
        className="btn"
        type="file"
        accept=".png, .jpg, .jpeg"
        id="fileID"
        onChange={onClick}
        multiple
      />
    );
  }

  if (tooltip === "") {
    return (
      <Button
        className={`border-none ${
          icon ? "" : "bg-blue-500 text-white"
        } px-10 py-15 cursor-pointer rounded hover:bg-blue-600   ${styleButton} `}
        data-ripple-light="true"
        onClick={onClick}
        disabled={disabled}
        htmlType={htmlType}
      >
        <span>
          {loading ? <LoadingOutlined className="ml-1 mr-2" /> : icon}
        </span>
        {children}
      </Button>
    );
  } else {
    return (
      <Tooltip placement="topLeft" title={tooltip}>
        <Button
          className={`border-none ${
            icon ? "" : "bg-blue-500 text-white"
          } px-10 py-15 cursor-pointer rounded hover:bg-blue-600   ${styleButton} `}
          data-ripple-light="true"
          onClick={onClick}
          disabled={disabled}
          htmlType={htmlType}
        >
          <span>
            {loading ? <LoadingOutlined className="ml-1 mr-2" /> : icon}
          </span>
          {children}
        </Button>
      </Tooltip>
    );
  }
};

export default ButtonApp;
