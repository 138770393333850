import { formatDate, handleDownload } from "../../utils";
import ButtonApp from "../Button";

const header = [
  "Nombre Producto",
  "Sku",
  "Precio",
  "Stock",
  "Imagen Cargada",
];

const ExcelProductsReport = ({ data }) => {
  const transformData = (originalData) => {
    const transformedArray = [];

    originalData.forEach((item) => {
      const transformedItem = [];
      transformedItem.push([
        item.name || "Sin Nombre",
        item.sku || "Sin Sku",
        item.price || 0,
        item.stock || 0,
        item.imageExist ? "Si" : "No",
      ]);

      transformedArray.push(...transformedItem);
    });

    return transformedArray;
  };

  const downloadExcel = () => {
    const result = transformData(data);
    handleDownload([header, ...result], "Reporte_Imagen_Cargada_Essity");
  };

  return (
    <div>
      {data.length == 0 ? (
        <ButtonApp children={"Descargar Excel"} disabled={true} />
      ) : (
        <ButtonApp
          children={"Descargar Excel"}
          onClick={() => downloadExcel()}
        />
      )}
    </div>
  );
};

export default ExcelProductsReport;
