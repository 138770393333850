import { useEffect, useState } from "react";
import { Header } from "../../components/Header";
import { ProductList } from "../../components/ProductList";
import { useShoppingCartContext } from "../../context/shoppingCard";
import { getMeUser } from "../../services/MeUserServices";
import LoadingSpinner from "../../components/LoadingSpinner";
import { useParams } from "react-router-dom";
import { decryptText } from "../../utils";

function Home() {
  const {
    setCountProducts,
    setAllProducts,
    countProducts,
    allProducts,
    setTotal,
    setUser,
    total,
    user,
  } = useShoppingCartContext();
  const [loading, setLoading] = useState(false);
  const { userId } = useParams();
  const module = sessionStorage.getItem("module");
  const moduleDecryptText = decryptText(module);

  const fetchData = async () => {
    setLoading(true);
    try {
      const datos = await getMeUser(userId);
      setUser(datos);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error al obtener datos:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return <LoadingSpinner text="Cargando..." />;
  }

  if (!user || user?.length === 0 || moduleDecryptText !== "ecommerce") {
    return <div className="m-6">No tiene acceso</div>;
  }

  return (
    <>
      <Header
        allProducts={allProducts}
        setAllProducts={setAllProducts}
        total={total}
        setTotal={setTotal}
        countProducts={countProducts}
        setCountProducts={setCountProducts}
        user={user}
      />
      <ProductList
        allProducts={allProducts}
        setAllProducts={setAllProducts}
        total={total}
        setTotal={setTotal}
        countProducts={countProducts}
        setCountProducts={setCountProducts}
        user={user}
      />
    </>
  );
}

export default Home;
