import { Card, Select } from "antd";
import React, { useState } from "react";
import Reports from "./salesNoteReport";
import ProductsReports from "./productsReport";

const { Option } = Select;

const ReportsHome = () => {
  const [selectProcess, setSelectProcess] = useState(null);

  const optionsProcess = [
    { value: 'SALES_NOTE', label: 'Reporte Nota de Ventas' },
    { value: 'PRODUCTS_IMAGE', label: 'Reporte Imagenes Cargadas' },
  ];

  const handleChange = (value) => {
    setSelectProcess(value);
  };

  const componentMap = {
    "SALES_NOTE": <Reports />,
    "PRODUCTS_IMAGE": <ProductsReports />,
  };

  const selectedComponent = componentMap[selectProcess] || (
    <div>
      <p>Debe seleccionar un Reporte</p>
    </div>
  );

  return (
    <div>
      <Card className="mb-2">
        <Select
          className="mt-2 mb-2 mr-2 w-60"
          defaultValue={{
            value: "NOT_DEFINITE",
            label: "Seleccionar Periodo",
          }}
          onChange={handleChange}
          options={optionsProcess}
        />
      </Card>
      <div>
        {selectedComponent}
      </div>
    </div>
  );
};

export default ReportsHome;
