import { useShoppingCartContext } from "../../context/shoppingCard";
import { salesNoteImport } from "../../services/SalesNoteServices";
import { Alert, Card, Table, Typography } from "antd";
import { add0InNumber, formatoMonedaChilena } from "../../utils";
import ButtonApp from "../../components/Button";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
var pdfMake = require("pdfmake/build/pdfmake.js");
var pdfFonts = require("pdfmake/build/vfs_fonts.js");
pdfMake.vfs = pdfFonts.pdfMake.vfs;
const { Text } = Typography;

function Preview({ setPage }) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { allProducts, total, user, setSaleNote } = useShoppingCartContext();
  
  const [monthString, yearString] = user?.processYearMonth?.split('-');
  const month = parseInt(monthString, 10);
  const year = parseInt(yearString, 10);
  
  const date = new Date(year, month - 1);
  const today = new Date();
  console.log(date)

  useEffect(() => {
    if (allProducts.length === 0 && total === 0) {
      navigate(`/`);
    }
  }, []);

  const dataSource = allProducts.map((x) => {
    return {
      name: x.name,
      price: formatoMonedaChilena(x.price),
      quantity: x.quantity,
      subPrice: formatoMonedaChilena(x.price * x.quantity),
    };
  });

  const generatePdf = (pdfDefinition) => {
    return new Promise((resolve, reject) => {
      try {
        const pdfDoc = pdfMake.createPdf(pdfDefinition);

        pdfDoc.getBuffer(
          (buffer) => {
            resolve(buffer);
          },
          (error) => {
            reject(error);
          }
        );
      } catch (error) {
        reject(error);
      }
    });
  };

  const onSubmit = async () => {
    setIsLoading(true);

    const body = {
      body: {
        totalPrice: total,
        processUsersId: user?.idProcessUsers,
        allProducts: allProducts.map((p) => {
          return {
            quantity: p.quantity,
            productsId: p.id,
            stock: p.stock,
          };
        }),
      },
      data: {
        dataSource,
        user,
        total,
      },
    };
    const result = await salesNoteImport(body);
    setSaleNote(result);
    setIsLoading(false);
    if (result) {
      setPage("3");
    }
  };

  const columns = [
    {
      title: "Nombre Producto",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Precio",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Cantidad",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Sub-Total",
      dataIndex: "subPrice",
      key: "subPrice",
    },
  ];

  return (
    <header className="flex flex-col items-center">
      <h1 className="text-3xl font-bold"> Vista previa de la Nota de Venta </h1>
      <Card className="w-full" bordered={false}>
        <Table
          dataSource={dataSource}
          columns={columns}
          footer={() => (
            <div className="flex flex-col md:flex-row-reverse items-center">
              <div className="order-2 md:order-none mb-2 md:mb-0">
                <ButtonApp
                  color="black"
                  children={"Confirmar Solicitud de Productos"}
                  onClick={() => onSubmit()}
                  loading={isLoading}
                  disabled={!user.acess}
                />
              </div>
              <div className="order-3 md:order-none mb-2 mr-2 md:mb-0">
                <ButtonApp
                  color="black"
                  children={"Volver"}
                  disabled={isLoading}
                  onClick={() => setPage("1")}
                />
              </div>
              {!user.acess && (
                <Alert
                  className="order-1 md:order-none mb-2 md:mb-0"
                  message="No tiene permiso de solicitar la nota de venta para este periodo"
                  type="warning"
                />
              )}
              <h1 className="font-bold m-1 order-4 md:order-none">
                Total: {formatoMonedaChilena(total)}{" "}
              </h1>
            </div>
          )}
          scroll={{ x: "max-content" }}
        />
        <div className="float-left text-left">
          <Card
            title={<h1 className="text-2xl">Detalle</h1>}
            bordered={false}
            style={{ width: 400 }}
          >
            <p>
              <Text strong>Nombre Completo:</Text> {user.fullName}
            </p>
            <p>
              <Text strong>Rut:</Text> {user.rut}
            </p>
            <p>
              <Text strong>Periodo: </Text>
              {user?.processYearMonth}
            </p>
            <p>
              <Text strong>Fecha Solicitud: </Text>
              {`${ add0InNumber(today.getDate()) }-${ add0InNumber(today.getMonth() + 1)}-${ today.getFullYear() }`}
            </p>
          </Card>
        </div>
      </Card>
    </header>
  );
}

export default Preview;
