const importTemplateProducts = {
  name: "Cargar Productos",
  key: "IMPORT_PRODUCTS",
  columns: [["Nombre", "SKU", "Precio", "Stock"]],
};

const importTemplateUpdateProducts = {
  name: "Cargar Stock de Productos",
  key: "IMPORT_UPDATE_PRODUCTS",
  columns: [["SKU", "Stock"]],
};

const importTemplateAcessUser = {
  name: "Deshabilitar Acesso Usuario",
  key: "IMPORT_ACESS_USER",
  columns: [["Rut"]],
};

const templateImports = [
  importTemplateAcessUser,
  importTemplateUpdateProducts,
  importTemplateProducts,
];

export default templateImports;
