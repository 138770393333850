import { formatDate, handleDownload } from "../../utils";
import ButtonApp from "../Button";

const header = [
  "Nombre Usuario",
  "Rut Usuario",
  "Fecha",
  "Número Guía",
  "Número Solicitud",
  "Valor Total",
  "Producto",
  "SKU",
  "Cantidad",
  "Precio",
  "Sub-Total",
];

const GenerateExcel = ({ data }) => {
  const transformData = (originalData) => {
    const transformedArray = [];

    originalData.forEach((item) => {
      const transformedItem = [];
      transformedItem.push([
        item.fullName || "Sin Nombre",
        item.rut || "Sin Rut",
        formatDate("DDMMYYYY", item.date),
        item.saleNoteId,
        item.requestNumber,
        item.totalPrice,
        "",
        "",
        "",
      ]);

      item.children.forEach((child) => {
        const { quantity, sku, name, price } = child;
        transformedItem.push([
          "",
          "",
          "",
          "",
          "",
          "",
          name,
          sku,
          quantity,
          price,
          quantity * price,
        ]);
      });

      transformedArray.push(...transformedItem);
    });

    return transformedArray;
  };

  const downloadExcel = () => {
    const result = transformData(data);
    handleDownload([header, ...result], "Reporte_Essity");
  };

  return (
    <div>
      {data.length == 0 ? (
        <ButtonApp children={"Descargar Excel"} disabled={true} />
      ) : (
        <ButtonApp
          children={"Descargar Excel"}
          onClick={() => downloadExcel()}
        />
      )}
    </div>
  );
};

export default GenerateExcel;
