import { Card, Table } from "antd";
import { useShoppingCartContext } from "../../context/shoppingCard";
import { formatoMonedaChilena } from "../../utils";
import ButtonApp from "../../components/Button";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";

function ShoppingCart({ setPage }) {
  const { allProducts, total, user } = useShoppingCartContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (allProducts.length === 0 && total === 0) {
      navigate(`/essity_rbk/ecommerce`);
    }
  }, []);

  const dataSource = allProducts.map((x) => {
    return {
      name: x.name,
      price: formatoMonedaChilena(x.price),
      quantity: x.quantity,
      subPrice: formatoMonedaChilena(x.price * x.quantity),
    };
  });
  const columns = [
    {
      title: "Nombre Producto",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Precio",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Cantidad",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Sub-Total",
      dataIndex: "subPrice",
      key: "subPrice",
    },
  ];

  return (
    <header className="flex flex-col items-center">
      <h1 className="text-3xl font-bold"> Carrito de compras </h1>
      <Card className="w-full" bordered={false}>
        <Table
          dataSource={dataSource}
          columns={columns}
          footer={() => (
            <div className="flex flex-col md:flex-row-reverse items-center">
              <div className="order-2 md:order-none mr-2 mb-2 md:mb-0">
                <ButtonApp
                  color="black"
                  children={"Solicitar Productos"}
                  onClick={() => setPage("2")}
                />
              </div>
              <div className="order-3 md:order-none mr-2">
                <ButtonApp
                  color="black"
                  children={
                    <Link to={"/essity_rbk/ecommerce/" + user.userId}>
                      Volver
                    </Link>
                  }
                />
              </div>
              <h1 className="font-bold m-1 order-1 md:order-none">
                Total: {formatoMonedaChilena(total)}{" "}
              </h1>
            </div>
          )}
          scroll={{ x: 'max-content' }}
        />
      </Card>
    </header>
  );
}

export default ShoppingCart;
