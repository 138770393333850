import { getUsersWithAcess } from "../../services/UsersServices";
import { VerticalAlignBottomOutlined } from "@ant-design/icons";
import templateImports from "../../constans/importTemplate";
import { formatErrors, handleDownload } from "../../utils";
import ButtonApp from "../../components/Button";
import React, { useState } from "react";
import {
  createProductsImport,
  updateAcesUserImport,
  updateProductsImport,
} from "../../services/ImportServices";
import { Card, Select } from "antd";
import * as XLSX from "xlsx";
import { useShoppingCartContext } from "../../context/shoppingCard";
import { message } from "../../components/Message";
import AlertMessage from "../../components/AlertMessage";

const ExcelImporter = () => {
  const { user } = useShoppingCartContext();
  const [excelData, setExcelData] = useState(null);
  const [selectData, setSelectData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [excelError, setExcelError] = useState(null);
  const [messageFinally, setMessageFinally] = useState([]);

  let optionsImport = templateImports.map((i) => {
    return {
      value: i.key,
      label: i.name,
    };
  });

  if ([1, 0].includes(user?.statusProcess)) {
    optionsImport = optionsImport.filter(
      (x) => !["IMPORT_PRODUCTS", "IMPORT_UPDATE_PRODUCTS"].includes(x.value)
    );
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      setExcelData(jsonData?.filter((x) => x.length > 0));
    };

    reader.readAsArrayBuffer(file);
  };

  const handleChange = async (value) => {
    setSelectData(value);
  };

  const downloadFile = async () => {
    const templateSelect = templateImports.find(
      (x) => x.key === selectData
    ).columns;
    switch (selectData) {
      case "IMPORT_PRODUCTS":
        handleDownload(templateSelect, "importar_productos");
        break;
      case "IMPORT_UPDATE_PRODUCTS":
        handleDownload(templateSelect, "editar_stock");
        break;
      case "IMPORT_ACESS_USER":
        /* const users = await getUsersWithAcess();
        const usersFormat = users?.map((u) => {
          return {
            rut: u.rut,
            acess: u.acess,
          };
        });
        const dataFormat = usersFormat.map((objeto) => Object.values(objeto)); */
        // const newData = [...templateSelect, ...(dataFormat || [[]])];
        handleDownload(templateSelect, "deshabilitar_acceso_usuario");
        break;
      default:
        break;
    }
  };

  const downloadFileError = async () => {
    const dataFormat = excelError.map((objeto) => Object.values(objeto));
    const header = ["Código", "error", "Solución", "Fila"];
    const newData = [header, ...(dataFormat || [[]])];
    handleDownload(newData, "Error importación");
  };

  const validateHeader = (array1, array2) => {
    for (let i = 0; i < array1.length; i++) {
      if (array1[i] !== array2[i]) {
        return false;
      }
    }

    return true;
  };

  const importFile = async () => {
    setIsLoading(true);
    let result;
    switch (selectData) {
      case "IMPORT_PRODUCTS":
        const headerProducts = templateImports.find(
          (t) => t.key === selectData
        );

        if (!validateHeader(headerProducts.columns[0], excelData[0])) {
          message.error(
            "Se ha encontrado una inconsistencia en la cabecera del archivo"
          );
        } else {
          result = await createProductsImport(excelData);
          setExcelError(formatErrors(result));
          if (!result) {
            setMessageFinally([...messageFinally, headerProducts.name]);
          }
        }
        break;
      case "IMPORT_UPDATE_PRODUCTS":
        const headerStock = templateImports.find((t) => t.key === selectData);
        if (!validateHeader(headerStock.columns[0], excelData[0])) {
          message.error(
            "Se ha encontrado una inconsistencia en la cabecera del archivo"
          );
        } else {
          result = await updateProductsImport(excelData);
          setExcelError(formatErrors(result));
          if (!result) {
            setMessageFinally([...messageFinally, headerStock.name]);
          }
        }
        break;
      case "IMPORT_ACESS_USER":
        const headerUsers = templateImports.find((t) => t.key === selectData);
        if (!validateHeader(headerUsers.columns[0], excelData[0])) {
          message.error(
            "Se ha encontrado una inconsistencia en la cabecera del archivo"
          );
        } else {
          result = await updateAcesUserImport(excelData);
          setExcelError(formatErrors(result));
          if (!result) {
            setMessageFinally([...messageFinally, headerUsers.name]);
          }
        }
        break;
      default:
        break;
    }

    setExcelData(null);
    setSelectData(null);
    setIsLoading(false);
  };

  return (
    <div>
      <div>
        { messageFinally?.map(x => (
          <AlertMessage
            title={"Finalizado"}
            message={
              `La importación ha finalizado correctamente la importación: ${x || ''}`
            }
            type='success'
            onClose={true}
          />
        ))}
      </div>
      <Select
        className="mt-2 mb-2 w-52"
        defaultValue={{
          value: "NOT_DEFINITE",
          label: "Selecionar Importación",
        }}
        onChange={handleChange}
        options={optionsImport}
      />
      {excelData && (
        <ButtonApp
          styleButton="ml-2"
          children={"Importar"}
          onClick={() => importFile()}
          loading={isLoading}
        />
      )}
      {selectData && (
        <div>
          <input
            className="shadow appearance-none border rounded w-96 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="file"
            accept=".xlsx, .xls"
            onChange={handleFileChange}
          />
          <ButtonApp
            styleButton="m-2 bg-blue-500 text-white"
            icon={<VerticalAlignBottomOutlined />}
            onClick={() => downloadFile()}
            tooltip="Descargar Archivo"
          />
        </div>
      )}
      {excelError && (
        <Card className="w-full mt-2" bordered={false}>
          <h2>Errores Encontrados</h2>
          <ButtonApp
            styleButton="m-2"
            icon={<VerticalAlignBottomOutlined />}
            onClick={() => downloadFileError()}
            tooltip="Descargar Archivo De Errores"
          />
          {/* <pre>{JSON.stringify(excelError, null, 2)}</pre> */}
        </Card>
      )}
    </div>
  );
};

export default ExcelImporter;
