import { useEffect, useState } from "react";
import { getProducts } from "../../services/ProductsServices";
import { getSignedUrl } from "../../services/uploadFile";
import { formatoMonedaChilena } from "../../utils";
import LoadingSpinner from "../LoadingSpinner";
import ReactPaginate from "react-paginate";
import { message } from "../Message";
import ButtonApp from "../Button";
import { Input } from "antd";

export const ProductList = ({
  allProducts,
  setAllProducts,
  countProducts,
  setCountProducts,
  total,
  setTotal,
  user,
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState(data);

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 6;

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const calculateCurrentItems = () => {
    return filteredData.slice(indexOfFirstItem, indexOfLastItem);
  };

  const handleSearch = (value) => {
    const filtered = data.filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filtered);
    setCurrentPage(0);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const datos = await getProducts(user?.processId);
      const newDatos = [];
      for await (const obj of datos) {
        const newUrl = await getSignedUrl(
          obj.url.includes(`${process.env.REACT_APP_NODE_ENV}/products/image/`)
            ? obj.url
            : `${process.env.REACT_APP_NODE_ENV}/products/image/${obj.sku}`
        );
        newDatos.push({
          ...obj,
          url: newUrl,
        });
      }
      setData(newDatos);
      setFilteredData(newDatos);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error al obtener datos:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onAddProduct = (product) => {
    if (!user.salesNoteAcess) {
      message.warning("Solo se permite una solicitud de productos por periodo");
      return;
    }
    if (!user.acess) {
      message.warning(
        "No tiene permiso de solicitar la nota de venta para este periodo"
      );
      return;
    }
    if (countProducts >= 10) {
      message.warning("Maximo 10 bultos");
      return;
    }
    if (
      user?.syndicateProdCount > 0 &&
      countProducts >= user?.syndicateProdCount
    ) {
      message.warning(
        `Maximo ${user.syndicateProdCount} bultos por el sindicato`
      );
      return;
    }
    if (allProducts.find((item) => item.id === product.id)) {
      const products = allProducts.map((item) =>
        item.id === product.id
          ? { ...item, quantity: (item.quantity || 1) + 1 }
          : item
      );

      const productStock = products.find((ps) => ps.id === product.id);
      if (productStock.quantity > productStock.stock) {
        message.warning("Sin Stock");
        return;
      } else {
        setTotal(total + product.price * (product.quantity || 1));
        setCountProducts(countProducts + (product.quantity || 1));
        return setAllProducts([...products]);
      }
    }
    setTotal(total + product.price * (product.quantity || 1));
    setCountProducts(countProducts + (product.quantity || 1));
    setAllProducts([
      ...allProducts,
      { ...product, quantity: product.quantity || 1 },
    ]);
    message.success("Producto añadido correctamente");
  };

  if (loading) {
    return <LoadingSpinner text="Cargando..." />;
  }

  const currentItems = calculateCurrentItems();

  return (
    <div className="mx-4 md:mx-8 lg:mx-16 xl:mx-24">
      <div className="mb-6 md:mb-12">
        <Input
          placeholder="Buscar productos"
          onChange={(e) => handleSearch(e.target.value)}
        />
      </div>
      <div className="mb-10 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {currentItems?.map((product) => (
          <div className="item" key={product.id}>
            <figure>
              <img
                className="w-full h-48 md:h-56 lg:h-64 object-cover rounded-t-lg transition-all duration-500"
                src={product.url}
                alt={"Sin imagen"}
              />
            </figure>
            <div className="info-product">
              <h2 className="h-12 text-base mb-9 md:text-sm lg:text-base xl:text-lg">
                {product.name}
              </h2>
              <p className="text-base md:text-sm lg:text-base xl:text-lg font-bold">
                {formatoMonedaChilena(product.price)}
              </p>
              <p className="text-sm md:text-base lg:text-sm xl:text-base">
                Stock Disponible: {product.stock}
              </p>
              <ButtonApp
                onClick={() => onAddProduct(product)}
                children={"Añadir al carrito"}
                disabled={product.stock <= 0}
              />
            </div>
          </div>
        ))}
      </div>
      {filteredData.length > itemsPerPage && (
        <ReactPaginate
          pageCount={Math.ceil(filteredData.length / itemsPerPage)}
          pageRangeDisplayed={5}
          marginPagesDisplayed={2}
          onPageChange={(selected) => setCurrentPage(selected.selected)}
          containerClassName={"pagination"}
          activeClassName={"active"}
          previousLabel={"Anterior"}
          nextLabel={"Siguiente"}
          breakLabel={"..."}
        />
      )}
    </div>
  );
};
