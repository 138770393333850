import * as XLSX from "xlsx";
import { v4 as uuidv4 } from "uuid";

export const formatoMonedaChilena = (num) => {
  const formatoMoneda = new Intl.NumberFormat("es-CL", {
    style: "currency",
    currency: "CLP",
  });
  return formatoMoneda.format(num);
};

export const handleDownload = (data, name) => {
  const ws = XLSX.utils.aoa_to_sheet(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Hoja1");
  XLSX.writeFile(wb, `${name || "archivo"}.xlsx`);
};

export const generateCode = () => {
  var caracteres = "abcdefghijkmnpqrtuvwxyzABCDEFGHJKMNPQRTUVWXYZ2346789";
  var value = "";
  for (let i = 0; i < 20; i++)
    value += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
  return value;
};

export const generateCodeOnly = () => {
  return uuidv4();
};

export const formatDate = (format, date) => {
  if (!date) return "Sin Fecha";
  const newDate = new Date(date);
  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };

  switch (format) {
    case "DDMMYYYY":
      return new Intl.DateTimeFormat("es-ES", options).format(newDate);
    default:
      return "Sin Fecha";
  }
};

export const formatErrors = (data) => {
  if (data === null) return data;

  const formatData = data?.map((d) => {
    return {
      "Código": d.code,
      "error": d.error,
      "Solución": d.solution,
      "Fila": d.row,
    };
  });
  return formatData;
};

export const encryptText = (originalText) => {
  const key = "essity_clave";
  const encrypted = btoa(encodeURIComponent(originalText + key));
  return encrypted;
};

export const decryptText = (encryptedText) => {
  const key = "essity_clave";
  const decrypted = atob(encryptedText)?.replace(key, "");
  return decrypted;
};

export const add0InNumber = (month) => {
  const result = month < 10 ? `0${month}` : `${month}`;

  return result;
}