import React from "react";
import { Spin } from "antd";

const LoadingSpinner = ({ text = "" }) => {
  return (
    <div className="flex items-center justify-center h-screen">
      <Spin size="large" />
      <span className="m-2 font-bold">{text}</span>
    </div>
  );
};

export default LoadingSpinner;
