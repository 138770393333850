import { useShoppingCartContext } from "../../context/shoppingCard";
import SalesNoteGenerate from "../../components/SalesNoteGenerate";
import { useNavigate } from "react-router-dom";
import { add0InNumber, formatoMonedaChilena } from "../../utils";
import ButtonApp from "../../components/Button";
import { Card, Typography } from "antd";
import { useEffect } from "react";
const { Text } = Typography;

function Buy({ user, total, allProducts }) {
  const { setCountProducts, setAllProducts, setTotal, saleNote, setSaleNote } =
    useShoppingCartContext();
  const navigate = useNavigate();
  
  const allData = {
    user,
    total,
    allProducts,
  };

  const [monthString, yearString] = allData?.user?.processYearMonth?.split('-');
  const month = parseInt(monthString, 10);
  const year = parseInt(yearString, 10);
  
  const date = new Date(year, month - 1);
  const today = new Date();
  console.log(date)

  useEffect(() => {
    if (!user && !total && !allProducts) {
      navigate(`/essity_rbk/ecommerce/${allData?.user?.userId}`);
    }
  }, []);

  const onClearCart = () => {
    const userId = user?.userId;
    setAllProducts([]);
    setTotal(0);
    setCountProducts(0);
    setSaleNote(null);

    navigate(`/essity_rbk/ecommerce/${userId}`);
  };

  const dataSource = allData.allProducts.map((x) => {
    return {
      name: x.name,
      price: formatoMonedaChilena(x.price),
      quantity: x.quantity,
      subPrice: formatoMonedaChilena(x.price * x.quantity),
    };
  });

  return (
    <header className="flex flex-col items-center">
      <h1 className="text-3xl font-bold">
        La Nota de Venta se genero exitósamente
      </h1>
      <div className="float text-left m-4">
        <Card
          title={<h1 className="text-2xl">Detalle</h1>}
          bordered={false}
          style={{ width: 400 }}
        >
          <p>
            <Text strong>Nombre Completo:</Text> {allData?.user?.fullName}
          </p>
          <p>
            <Text strong>Rut:</Text> {allData?.user?.rut}
          </p>
          <p>
            <Text strong>Periodo: </Text>
            {allData?.user?.processYearMonth}
          </p>
          <p>
            <Text strong>Número Solicitud: </Text>
            {saleNote?.requestNumber || ''}
          </p>
          <p>
            <Text strong>Fecha Solicitud: </Text>
            {`${ add0InNumber(today.getDate()) }-${ add0InNumber(today.getMonth() + 1) }-${ today.getFullYear() }`}
          </p>
          <div className="my-3">
            <ButtonApp
              color="black"
              children={"Inicio"}
              onClick={() => onClearCart()}
            />
          </div>
        </Card>
        <div className="mt-4 text-center">
          <SalesNoteGenerate data={dataSource} total={allData?.total} saleNote={saleNote} />
        </div>
      </div>
    </header>
  );
}

export default Buy;
