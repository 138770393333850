import React, { useEffect, useState } from "react";
import { Card, Table } from "antd";
import ButtonApp from "../Button";
import { message } from "../Message";
import {
  getUsers,
  sendEmailOrderReady,
  updateAcess,
} from "../../services/UsersServices";
import LoadingSpinner from "../LoadingSpinner";
import {
  CheckCircleOutlined,
  CheckSquareOutlined,
  CloseCircleOutlined,
  RedoOutlined,
} from "@ant-design/icons";
import { syncUpProcessUser } from "../../services/PeriodsServices";
import { useShoppingCartContext } from "../../context/shoppingCard";

const Acess = () => {
  const { user } = useShoppingCartContext();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const datos = await getUsers();
      setData(
        datos.map((x) => {
          return {
            ...x,
            acess: x.acess ? "Con Acceso" : "Sin Acceso",
          };
        })
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error al obtener datos:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onFinish = async (id, acess) => {
    if (id === null || id === undefined) {
      message.error("Ha ocurrido un error");
      return;
    }
    setLoading2(true);
    const data = {
      acess: acess === "Con Acceso" ? false : true,
    };
    const respont = await updateAcess(data, id);
    setLoading2(false);
    fetchData();
  };

  const sendEmailUser = async (id) => {
    if (id === null || id === undefined) {
      message.error("Ha ocurrido un error");
      return;
    }
    setLoading2(true);
    const respont = await sendEmailOrderReady(id);
    setLoading2(false);
  };

  const onFinishsyncUpProcessUser = async () => {
    setLoading2(true);
    const body = {
      id: user.processId,
      year: "",
    };
    const respont = await syncUpProcessUser(body);
    fetchData();
    setLoading2(false);
  };

  const columns = [
    {
      title: "Nombre Usuario",
      dataIndex: "name",
      key: "name",
      filters: data?.map((d) => {
        return {
          text: d.name,
          value: d.name,
        };
      }),
      onFilter: (value, record) => record.name.indexOf(value) === 0,
      filterSearch: true,
    },
    {
      title: "Rut",
      dataIndex: "rut",
      key: "rut",
      filters: data?.map((d) => {
        return {
          text: d.rut,
          value: d.rut,
        };
      }),
      onFilter: (value, record) => record.rut.indexOf(value) === 0,
      filterSearch: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      filters: data
        ?.filter(
          (obj, index, self) =>
            index === self.findIndex((o) => o.email === obj.email)
        )
        ?.map((d) => {
          return {
            text: d.email,
            value: d.email,
          };
        }),
      onFilter: (value, record) => record.email.indexOf(value) === 0,
      filterSearch: true,
    },
    {
      title: "Periodo",
      dataIndex: "yMonth",
      key: "yMonth",
    },
    {
      title: "Acceso",
      dataIndex: "acess",
      key: "acess",
      filters: [
        {
          text: "Con Acceso",
          value: "Con Acceso",
        },
        {
          text: "Sin Acceso",
          value: "Sin Acceso",
        },
      ],
      onFilter: (value, record) => record.acess.indexOf(value) === 0,
      filterSearch: true,
    },
    {
      title: "Aciones",
      key: "action",
      render: (_, record) => {
        return (
          <div>
            <ButtonApp
              loading={loading2}
              icon={
                record.acess !== "Con Acceso" ? (
                  <CheckCircleOutlined />
                ) : (
                  <CloseCircleOutlined />
                )
              }
              styleButton="mr-2 bg-blue-500 text-white"
              tooltip={
                record.acess === "Con Acceso" ? "Desabilitar" : "Habilitar"
              }
              onClick={() => onFinish(record.id, record.acess)}
              disabled={!record.acess}
            />
            {/* <ButtonApp
              icon={<CloseCircleOutlined />}
              styleButton="mr-2 bg-blue-500 text-white"
              tooltip={"Desabilitar"}
              onClick={() => onFinish(record.id, record.acess)}
              disabled={record.acess === "Con Acceso" ? false : true}
            /> */}
            <ButtonApp
              styleButton="mr-2 bg-blue-500 text-white"
              icon={<CheckSquareOutlined />}
              tooltip="Notificar retiro en bodega"
              onClick={() => sendEmailUser(record.rut)}
              loading={loading2}
            />
          </div>
        );
      },
    },
  ];

  if (loading) {
    return <LoadingSpinner text="Cargando..." />;
  }

  return (
    <div>
      <Card className="w-full" bordered={false}>
        <div className=" flex justify-end">
          <ButtonApp
            icon={<RedoOutlined className="mr-2" />}
            children={"Sincronizar Usuarios"}
            styleButton="mt-2 mb-2 bg-blue-500 text-white"
            tooltip="Sincronizar Usuarios"
            onClick={() => onFinishsyncUpProcessUser()}
            loading={loading2}
          />
        </div>
        <Table dataSource={data} columns={columns} scroll={{ x: 'max-content' }} />
      </Card>
    </div>
  );
};

export default Acess;
