import { message } from "../components/Message";

const { REACT_APP_URL_API_NESTJS, REACT_APP_NODE_ENV } = process.env;
// const port = REACT_APP_NODE_ENV == 'dev' ? ':80' : REACT_APP_NODE_ENV == 'prod' ? ':8080' : ''
const url = `${REACT_APP_URL_API_NESTJS}/process`;

const getPeriods = async () => {
  const token = sessionStorage.getItem("token");
  try {
    const respuesta = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    });

    const datos = await respuesta.json();
    if (!datos.status) {
      return [];
    }
    return datos.data;
  } catch (error) {
    message.error("Ha ocurrido un error");
    console.error("Error:", error);
  }
};

const createPeriods = async (data) => {
  const token = sessionStorage.getItem("token");
  try {
    const respuesta = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    const datos = await respuesta.json();
    if (!datos.status) {
      message.error(datos.message);
    } else {
      message.success(datos.message);
    }

    return datos.data;
  } catch (error) {
    message.error("Ha ocurrido un error");
    console.error("Error:", error);
  }
};

const syncUpProcessUser = async (data) => {
  const token = sessionStorage.getItem("token");
  try {
    const respuesta = await fetch(`${url}/syncUpProcess`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    const datos = await respuesta.json();
    if (!datos.status) {
      message.error(datos.message);
    } else {
      message.success(
        `${datos.message}, ${
          datos.data?.[0]?.toString() || ""
        } usuarios sincronizados`
      );
    }

    return datos.data;
  } catch (error) {
    message.error("Ha ocurrido un error");
    console.error("Error:", error);
  }
};

const updateStatusPeriods = async (data, id) => {
  const token = sessionStorage.getItem("token");
  try {
    const respuesta = await fetch(`${url}/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    const datos = await respuesta.json();
    if (!datos.status) {
      message.error(datos.message);
    } else {
      message.success(datos.message);
    }

    return datos.data;
  } catch (error) {
    message.error("Ha ocurrido un error");
    console.error("Error:", error);
  }
};

export { getPeriods, createPeriods, syncUpProcessUser, updateStatusPeriods };
