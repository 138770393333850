import React from "react";
import { Result, Button } from "antd";

const NotFoundPage = () => {
  return (
    <div className="flex items-center justify-center h-screen">
      <Result
        status="404"
        title="404"
        subTitle="Lo siento, la página que estás buscando no existe."
        extra={
          <Button type="primary" href="/essity_rbk/ecommerce">
            Volver a la página de inicio
          </Button>
        }
      />
    </div>
  );
};

export default NotFoundPage;
