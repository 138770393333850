import "./App.css";
import RouterApp from "./components/RouterApp";
import { ShoppingCartProvider } from "./context/shoppingCard";
import { ConfigProvider } from 'antd';
import esES from 'antd/lib/locale/es_ES';

window['process.env.NODE_TLS_REJECT_UNAUTHORIZED'] = '0';
 
function App() {
  return (
    <div className="App">
      <ConfigProvider locale={esES}>
        <ShoppingCartProvider>
          <RouterApp />
        </ShoppingCartProvider>
      </ConfigProvider>
    </div>
  );
}

export default App;
