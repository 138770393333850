import { message } from "../components/Message";

const { REACT_APP_URL_API_NESTJS, REACT_APP_NODE_ENV } = process.env;
// const port = REACT_APP_NODE_ENV == 'dev' ? ':80' : REACT_APP_NODE_ENV == 'prod' ? ':8080' : ''
const url = `${REACT_APP_URL_API_NESTJS}/report`;

const getReports = async (id, start, end) => {
  const token = sessionStorage.getItem("token");
  try {
    const respuesta = await fetch(`${url}/${id}/${start}/${end}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "authorization": `Bearer ${token}`
      },
    });

    const datos = await respuesta.json();
    if (!datos.status) {
      return [];
    }
    return datos.data;
  } catch (error) {
    message.error("Ha ocurrido un error");
    console.error("Error:", error);
  }
};
export { getReports };
