import { Alert } from "antd";

const onCloseFunc = (e) => {
  console.log(e, "I was closed.");
};

const AlertMessage = ({
  message,
  title,
  type = "warning",
  onClose = false,
}) => {
  if (onClose) {
    return (
      <Alert
        message={title}
        description={message}
        type={type}
        closable
        onClose={onCloseFunc}
      />
    );
  } else {
    return <Alert message={title} description={message} type={type} />;
  }
};

export default AlertMessage;
