import { Card, Select, Table } from "antd";
import React, { useEffect, useState } from "react";
import { getPeriods } from "../../services/PeriodsServices";
import ButtonApp from "../Button";
import { getReports } from "../../services/ReportsServices";
import {
  formatDate,
  formatoMonedaChilena,
} from "../../utils";
import GenerateExcel from "../GenerateExcel";
import { message } from "../Message";
import LoadingSpinner from "../LoadingSpinner";
import { getProducts } from "../../services/ProductsServices";
import { useShoppingCartContext } from "../../context/shoppingCard";
import { getProductsWithoutImage } from "../../services/uploadFile";
import ExcelProductsReport from "../GenerateExcel/excelProductsReport";

const ProductsReports = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [reportData, setReportData] = useState(null);
  const [optionData, setOptionData] = useState(null);
  const [selectProcess, setSelectProcess] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const datos = await getPeriods();
      setOptionData(datos);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error al obtener datos:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const optionsProcess = optionData?.map((i) => {
    return {
      value: i.id,
      label: `${i.year}-${i.month} ${
        i.isOpen && i.status >= 1 ? "Abierto" : "Cerrado"
      }`,
    };
  });

  const generateReport = async () => {
    if (!selectProcess) {
      message.warning("Debe selecionar un periodo");
      return;
    }
    setIsLoading(true);
    const data = await getProducts(selectProcess);
    const result = await getProductsWithoutImage(data);
    
    setReportData(result);
    setIsLoading(false);
  };

  const handleChange = async (value) => {
    setSelectProcess(value);
  };

  const columns = [
    {
      title: "Nombre Producto",
      dataIndex: "name",
      key: "name",
      filters: reportData?.map((d) => {
        return {
          text: d.name,
          value: d.name,
        };
      }),
      onFilter: (value, record) => record.name.startsWith(value),
      filterSearch: true,
    },
    {
      title: "Sku",
      dataIndex: "sku",
      key: "sku",
      filters: reportData?.map((d) => {
        return {
          text: d.sku,
          value: d.sku,
        };
      }),
      onFilter: (value, record) => record.sku.indexOf(value) === 0,
      filterSearch: true,
    },
    {
      title: "Precio",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Stock",
      dataIndex: "stock",
      key: "stock",
      width: 100,
    },
    {
      title: "Imagen Cargada",
      dataIndex: "imageExist",
      key: "imageExist",
      filters: [{ text: 'Si', value: true }, { text: 'No', value: false }],
      onFilter: (value, record) => record.imageExist === value,
      filterSearch: true,
      render: (_, record) => (
        <div> { record.imageExist ? "Si" : "No" } </div>
      ),
    }
  ];

  if (loading) {
    return <LoadingSpinner text="Cargando..." />;
  }

  return (
    <div>
      <Select
        className="mt-2 mb-2 mr-2 w-60"
        defaultValue={{
          value: "NOT_DEFINITE",
          label: "Selecionar Periodo",
        }}
        onChange={handleChange}
        options={optionsProcess}
        disabled={isLoading}
      />
      <ButtonApp
        children={"Generar Reporte"}
        onClick={() => generateReport()}
        styleButton="m-2"
        loading={isLoading}
        disabled={isLoading}
      />
      {reportData && (
        <Card className="w-full mt-2" bordered={false}>
          {/* <h2>Report Data</h2> */}
          {/* <pre>{JSON.stringify(reportData, null, 2)}</pre> */}
          <div className="mb-2 flex justify-end">
            <ExcelProductsReport data={reportData} />
          </div>
          <Table
            columns={columns}
            dataSource={reportData}
            scroll={{ x: "max-content" }}
          />
        </Card>
      )}
    </div>
  );
};

export default ProductsReports;
