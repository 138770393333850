import React, { useEffect, useState } from "react";
import { DatePicker, Card, Col, Row, Modal } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import ButtonApp from "../Button";
import {
  createPeriods,
  getPeriods,
  syncUpProcessUser,
  updateStatusPeriods,
} from "../../services/PeriodsServices";
import { message } from "../Message";
import LoadingSpinner from "../LoadingSpinner";
import { sendEmailProductsReady } from "../../services/UsersServices";
import {
  CloseOutlined,
  NotificationOutlined,
  RedoOutlined,
} from "@ant-design/icons";
dayjs.extend(customParseFormat);

const Periods = ({ setReload }) => {
  const [dateForm, setDateForm] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingPost, setLoadingPost] = useState(false);
  const [loadingSendEmail, setLoadingSendEmail] = useState(false);
  const [data, setData] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState({
    showModal: false,
    data: null,
  });

  const dateDisable = data?.find((x) => x.isOpen);
  const date = new Date(dateDisable?.year, dateDisable?.month);
  date.setHours(14, 0, 0, 999);

  const disabledDate = (current) => {
    const currentDate = new Date();
    currentDate.setHours(14, 0, 0, 999);

    return (current && current < date) || current < currentDate;
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const datos = await getPeriods();
      setData(datos);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error al obtener datos:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDateChange = (date, dateString) => {
    setDateForm(dateString);
  };

  const onFinish = async () => {
    if (dateForm === null || dateForm === "") {
      message.warning("Debe selecionar el mes y año");
      return;
    }
    const data = {
      year: dateForm.split("-")[0],
      month: dateForm.split("-")[1],
      isOpen: true,
      status: 2,
    };
    setLoadingPost(true);
    const respont = await createPeriods(data);
    setLoadingPost(false);
    fetchData();
    setReload();
  };

  const onFinishUpdateStatus = async () => {
    const body = {
      status: 0,
    };
    setLoadingPost(true);
    const respont = await updateStatusPeriods(body, isModalVisible.data.id);
    setLoadingPost(false);
    setIsModalVisible({ showModal: true, data });
    fetchData();
    setReload();
  };

  const onFinishProducts = async (id) => {
    setLoadingSendEmail(true);
    const respont = await sendEmailProductsReady(id);
    setLoadingSendEmail(false);
  };

  const showModalDelete = (data) => {
    setIsModalVisible({ showModal: true, data });
  };

  const handleCancel = () => {
    setIsModalVisible({ showModal: false, data });
  };

  /* const onFinishsyncUpProcessUser = async (id, year) => {
    setLoadingSendEmail(true);
    const body = {
      id,
      year,
    };
    const respont = await syncUpProcessUser(body);
    setLoadingSendEmail(false);
  }; */

  if (loading) {
    return <LoadingSpinner text="Cargando..." />;
  }

  return (
    <div>
      <Card className="mb-6">
        <DatePicker.MonthPicker
          className="mr-2 mt-2 mb-2"
          format="YYYY-MM"
          placeholder="Seleccione año y mes"
          onChange={handleDateChange}
          disabledDate={disabledDate}
        />
        <ButtonApp
          children={"Abrir Periodo"}
          onClick={() => onFinish()}
          loading={loadingPost}
          disabled={data?.find((x) => x.isOpen)?.status >= 1}
        />
      </Card>
      <Row gutter={16}>
        {data?.map((p) => {
          return (
            <Col xs={24} sm={12} md={8} lg={6} xl={4}>
              <Card
                className="mb-6"
                title={`${p.year}-${p.month}`}
                bordered={false}
                key={p.month}
              >
                {p.isOpen && p.status >= 1
                  ? "Periodo Abierto"
                  : "Periodo Cerrado"}
                <ButtonApp
                  icon={<NotificationOutlined />}
                  visible={p.isOpen && p.status >= 1}
                  styleButton="mt-2 mb-2 w-full"
                  tooltip="Notificar Productos"
                  onClick={() => onFinishProducts(p.id)}
                  loading={loadingSendEmail}
                />
                {/* <ButtonApp
                  icon={<RedoOutlined />}
                  visible={p.isOpen && p.status == 1}
                  styleButton="mt-2 mb-2 w-full"
                  tooltip="Sincronizar Usuarios"
                  onClick={() => onFinishsyncUpProcessUser(p.id, p.year)}
                  loading={loadingSendEmail}
                /> */}
                <ButtonApp
                  icon={<CloseOutlined />}
                  visible={p.isOpen && p.status == 1}
                  styleButton="mt-2 mb-2 w-full"
                  tooltip="Cerrar Periodo"
                  onClick={() => showModalDelete(p)}
                  loading={loadingSendEmail}
                />
              </Card>
            </Col>
          );
        })}
      </Row>
      <Modal
        title="Confirmar Cierre de Periodo"
        onOk={() => onFinishUpdateStatus()}
        onCancel={() => handleCancel()}
        open={isModalVisible.showModal}
        okButtonProps={{
          style: {
            backgroundColor: "rgb(59 130 246)",
            borderColor: "rgb(59 130 246)",
          },
        }}
      >
        <h1 className="mb-2">
          Periodo:{" "}
          {`${isModalVisible?.data?.year}-${isModalVisible?.data?.month}`}
        </h1>
        <p>¿Estás seguro de que quieres cerrar este periodo?</p>
      </Modal>
    </div>
  );
};

export default Periods;
