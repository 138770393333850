import { useShoppingCartContext } from "../../context/shoppingCard";
import LoadingSpinner from "../../components/LoadingSpinner";
import { getMeUser } from "../../services/MeUserServices";
import AlertMessage from "../../components/AlertMessage";
import Products from "../../components/Products";
import Periods from "../../components/Periods";
import Reports from "../../components/reports";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Acess from "../../components/Acess";
import ExcelImporter from "../Import";
import { Card, Tabs } from "antd";
import ButtonApp from "../../components/Button";
import { HomeOutlined } from "@ant-design/icons";
import SyndicateConfig from "../../components/SyndicateConfig";
import { decryptText } from "../../utils";
import ReportsHome from "../../components/reports";
const { REACT_APP_URL_RUBRIKA_APP } = process.env;

function Admin() {
  const [selectedTab, setSelectedTab] = useState("1");
  const { setUser, user } = useShoppingCartContext();
  const [isVisibleHeader, setIsVisibleHeader] = useState(true);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(1);
  const module = sessionStorage.getItem("module");
  const moduleDecryptText = decryptText(module);

  const { userId } = useParams();
  const fetchData = async () => {
    setLoading(true);
    try {
      const datos = await getMeUser(userId);
      setUser(datos);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error al obtener datos:", error);
    }
  };

  useEffect(() => {
    fetchData();
    const handleResize = () => {
      setIsVisibleHeader(window.innerWidth >= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    fetchData();
  }, [reload]);

  const panels = [
    {
      key: "1",
      label: "Periodos",
      children: <Periods key={"1"} setReload={() => setReload(reload + 1)} />,
      disabled: false,
    },
    {
      key: "2",
      label: "Importadores",
      children: <ExcelImporter key={"2"} />,
      disabled: user?.length === 0 || !user?.processId,
    },
    {
      key: "3",
      label: "Productos",
      children: <Products key={"3"} />,
      disabled: user?.length === 0 || !user?.processId,
    },
    {
      key: "4",
      label: "Usuarios",
      children: <Acess key={"4"} />,
      disabled: user?.length === 0 || !user?.processId,
    },
    {
      key: "5",
      label: "Reportes",
      children: <ReportsHome key={"5"} />,
      disabled: user?.length === 0 || !user?.processId,
    },
    {
      key: "6",
      label: "Configuración Sindicato",
      children: <SyndicateConfig key={"6"} />,
      disabled: user?.length === 0 || !user?.processId,
    },
  ];

  if (loading) {
    return <LoadingSpinner text="Cargando..." />;
  }

  if (!user || user?.length === 0 || moduleDecryptText !== "admin") {
    return <div className="m-6">No tiene acceso</div>;
  }

  return (
    <header className="flex flex-col items-center" key={`${reload}reload`}>
      {isVisibleHeader ? (
        <div className="flex w-full">
          <div className="flex w-1/2 text-left justify-start">
            <h1 className="text-left text-3xl font-bold">Administrador</h1>
          </div>
          <div className="flex w-1/2 text-right justify-end">
            <ButtonApp
              children={
                <Link to={REACT_APP_URL_RUBRIKA_APP}>
                  {" "}
                  Rubrika{" "}
                </Link>
              }
              icon={<HomeOutlined />}
              tooltip="Volver a Rubrika"
              styleButton="mr-2"
            />
            <h1 className="periodoTitle text-right text-2xl font-bold">
              Periodo: {user.processYearMonth || "Sin Periodo"}
            </h1>
          </div>
        </div>
      ) : (
        <div className="flex w-full flex-col ml-2">
          <div className="flex w-1/2 items-start">
            <h1 className="text-left text-3xl font-bold">Administrador</h1>
          </div>
          <div className="flex w-1/2 items-start">
            <ButtonApp
              children={
                <Link to={REACT_APP_URL_RUBRIKA_APP}>
                  {" "}
                  Rubrika{" "}
                </Link>
              }
              icon={<HomeOutlined />}
              tooltip="Volver a Rubrika"
              styleButton="mt-2 mb-2" // bg-blue-500 text-white
            />
          </div>
          <div className="flex w-1/2 items-start">
            <h1 className="periodoTitle text-right text-2xl font-bold">
              Periodo: {user.processYearMonth || "Sin Periodo"}
            </h1>
          </div>
        </div>
      )}
      {user?.length === 0 && (
        <div className="mt-4 w-full text-left">
          <AlertMessage
            title={"Advertencia"}
            message={"Primero debe abrir un periodo"}
          />
        </div>
      )}
      <Card className="w-full mt-10" bordered={false}>
        <div className="text-left justify-center">
          <Tabs
            key={selectedTab}
            activeKey={selectedTab}
            onChange={(activeKey) => setSelectedTab(activeKey)}
            tabPosition={"top"}
            items={panels}
          />
        </div>
      </Card>
    </header>
  );
}

export default Admin;
