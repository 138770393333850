import { useShoppingCartContext } from "../../context/shoppingCard";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import ShoppingCart from "../ShoppingCart";
import Preview from "../Preview";
import Buy from "../Buy";

function ShoppingPage() {
  const [page, setPage] = useState("1");
  const { allProducts, total, user } = useShoppingCartContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (allProducts.length === 0 && total === 0) {
      navigate(`/essity_rbk/ecommerce`);
    }
  }, []);

  if (!user || user?.length === 0) {
    return <div className="m-6">No tiene acceso</div>;
  }

  return (
    <>
      {page === "1" ? (
        <ShoppingCart key={"ShoppingCart1"} setPage={setPage} />
      ) : page === "2" ? (
        <Preview key={"Preview2"} setPage={setPage} />
      ) : page === "3" ? (
        <Buy key={"Buy3"} user={user} total={total} allProducts={allProducts} />
      ) : (
        <></>
      )}
    </>
  );
}

export default ShoppingPage;
